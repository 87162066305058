import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Layout, Paragraph } from "~/components";
import { chardhamLinks } from "~/constants/links";

const BlogDetails: React.FC = () => {
  return (
    <Layout
      meta="Why do the yatra to Chardham by Helicopter with Thumby Aviation? Credentialed pilots, experienced ground staff, dedicated customer support, best hotels, customizable packages and much more."
      links={chardhamLinks}
    >
      <div className="mx-auto text-justify px-10">
        <div className="breadcrumb">
          <div className="pt-4">
            <p className="text-sm text-gray-500 font-medium">
              <a href="/">Home</a> {">"} <a href="/blogs">Blogs</a> {"> "}
              Chardham by Helicopter: Best Service Provider
            </p>
          </div>
        </div>
        <Heading type="h1" className="text-center text-[28px]">
          Chardham by Helicopter: Best Service Provider
        </Heading>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/blog-cover-6.jpg"
            alt="Thumby aviation"
            placeholder="blurred"
            objectFit="cover"
            className="w-[500px] h-[300px] text-center"
          />
        </div>
        <br />
        <Paragraph>
          Dear Yatri, now that you have decided to do your Yatra to Chardham by
          Helicopter, it is now time to make your decision on the choice of
          operator you would travel with. In case you are yet to make up your
          mind, read our <a href="/blogs">blogs</a>, check out{" "}
          <a href="/chardham-by-helicopter/packages">packages</a>,{" "}
          <a href="/chardham-by-helicopter/#our-clients">clients</a>,{" "}
          <a href="/chardham-by-helicopter/#testimonials">testimonials</a>,{" "}
          <a href="/chardham-by-helicopter/#photo-gallery">photo gallery</a>,{" "}
          <a href="/chardham-by-helicopter/#faq">FAQs</a> and much more.
        </Paragraph>

        <Paragraph>
          Coming back, the ideal choice as your partner in the sky for chartered
          services to the pilgrimage yatra should have a crew of credentialed
          pilots and experienced ground staff assistance at the helipad,
          excellent customer support, customizable packages inclusive of
          helicopter service, accommodation at Dehradun and all the Chardhams,
          ie: Yamunotri, Gangotri, Kedarnath, and Badrinath, Kedarnath shuttle
          services, VIP darshan passes, meal inclusions and on ground travel. In
          this context, we will make our case as to why we believe that Thumby
          Aviation is the best service provider for the{" "}
          <a href="/chardham-by-helicopter">Chardham Yatra by Helicopter.</a>
        </Paragraph>
        <br />
        <Heading type="h2" className="text-center text-[24px]">
          Why Thumby Aviation?
        </Heading>

        <Heading type="h3" className="text-[20px]">
          Credentialed Pilots and Experienced Ground Staff
        </Heading>
        <Paragraph>
          Thumby Aviation has a wide portfolio of credentialed pilots with
          significant experiences in the armed forces and civil aviation. This
          experience will be essential to the guarantee of safety and
          comfortable flying in the Himalayan Terrains. Additionally, the team
          of experienced ground staff will ensure hassle-free operations
          ensuring time efficiency and smooth functioning at the helipads.
        </Paragraph>

        <Heading type="h3" className="text-[20px]">
          Adherence to all DGCA guidelines
        </Heading>
        <Paragraph>
          As a responsible operator, we adhere to all safety guidelines issued
          by the DGCA and other government authorities. Additionally, we have
          taken extra care to develop further safety mechanisms to ensure that
          no stone is left unturned from the perspective of safety and security.
        </Paragraph>

        <Heading type="h3" className="text-[20px]">
          Customizable Packages{" "}
        </Heading>
        <Paragraph>
          Our wide range of all-inclusive customizable packages whose detailed
          and intricate itinerary include luxurious accommodations at Dehradun
          and each of the Chardhams, meal inclusions with special meal requests,
          VIP darshan pass, Kedarnath Shuttle service(only for charter
          packages), road transits, local sight-seeing options and much more.
          Read more about the{" "}
          <a href="/chardham-by-helicopter/packages">packages</a> or download
          itinerary.
        </Paragraph>

        <Heading type="h3" className="text-[20px]">
          Excellent Customer Support{" "}
        </Heading>
        <Paragraph>
          We ensure excellent customer support starting from Dehradun airport to
          Yamunotri, Gangotri, Kedarnath, and Badrinath. Also, a dedicated
          customer support executive will be available to solve any customer
          query at all times. To know more about our customer service, checkout
          our <a href="/chardham-by-helicopter/#our-clients">clients</a> and{" "}
          <a href="/chardham-by-helicopter/#testimonials">testimonials</a>.{" "}
        </Paragraph>

        <Heading type="h3" className="text-[20px]">
          Conclusion
        </Heading>
        <Paragraph>
          In conclusion, we believe that all Yatris should get the ultimate
          spiritual experience ensuring comfort and utmost safety. At Thumby, we
          stand for the promise of excellent customer service and customer
          satisfaction.
        </Paragraph>
      </div>
    </Layout>
  );
};

export default BlogDetails;
